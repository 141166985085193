import React from "react"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"
import Form from "../components/form"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Logo style={{ width: "60%", margin: "0 auto", display: "block" }} />
    <h2 style={{ textAlign: "center", marginTop: "2em" }}>Contact us</h2>
    <Form />
  </Layout>
)

export default IndexPage
